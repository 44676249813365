/* You can add global styles to this file, and also import other style files */
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-radio-label {
  white-space: normal !important;
}

.no-padding .mat-horizontal-content-container {
  padding: 0px;
}
